<template>
  <div class="w-m-content financeiro-window" v-if="loading">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">
        <u-fake-loader />
      </p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <u-fake-loader width="100%" />
      <u-fake-loader width="50%" />
      <u-fake-loader width="60%" />
      <u-fake-loader width="10%" />
    </div>
  </div>
  <form v-else-if="!preventBind" class="w-m-content financeiro-window"> <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">
        <span v-if="!id">Novo Registro de Proposta no Bem</span>
        <span v-else>Propota ID {{model.id}}</span>
      </p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <e-row mr>
        <e-col>
          <erp-s-field label="Data">
            <erp-input placeholder="dd/mm/aaaa hh:mm" v-model="model.createdAt" v-mask="'##/##/#### ##:##'"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Pessoa Cadastrada">
            <pessoa-input v-model="model.pessoa" />
            <div v-if="model.pessoa && model.pessoa.id" class="m-t-xs font-12 text-blue-grey-5">Cadastro ID #{{model.pessoa.id}}, <a @click="gotoPerson(model.pessoa)">clique para visualizar</a>.
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Nome">
            <erp-input autofocus placeholder="Proponente" v-model="model.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Email">
            <erp-input placeholder="E-mail" v-model="model.email"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Telefone">
            <erp-input placeholder="Telefone" v-model="model.telefone"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <textarea class="sl-textarea" placeholder="Observações sobre a proposta" v-model="model.mensagem" rows="8"></textarea>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Valor da proposta">
            <erp-input v-model.lazy="model.valor" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Forma de pagamento">
            <select v-model="model.parcelado" class="sl-input input-sm">
              <option :value="false">À vista</option>
              <option :value="true">Parcelado</option>
            </select>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Valor da entrada">
            <erp-input :disable="!model.parcelado" v-model.lazy="model.valorEntrada" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Quantidade de parcelas">
            <erp-input :disable="!model.parcelado" v-model.lazy="model.parcelas" type="number" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Índice de correção">
            <select :disabled="!model.parcelado" v-model="model.indice" class="sl-input input-sm">
              <option :value="null">Qualquer</option>
              <option value="IPCA">IPCA</option>
              <option value="IGP-M">IGP-M</option>
              <option value="IPA">IPA</option>
              <option value="IPC">IPC</option>
              <option value="INCC">INCC</option>
              <option value="Taxa Selic">Taxa Selic</option>
            </select>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Situação">
            <select class="sl-input input-sm" :class="{'bg-blue-2': model.status === 1, 'bg-red-2': model.status === 99, 'bg-green-2': model.status === 100}" v-model="model.status">
              <option :value="0">Recebida</option>
              <option :value="1">Em análise</option>
              <option :value="99">Recusado</option>
              <option :value="100">Aprovado</option>
            </select>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left font-12">
        Registrado com o IP {{model.ip}}
      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right"/>
      </div>
    </div>
  </form>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import {datetimeToEn} from "@/utils/date"
import {find, updateProposta, newProposta} from "@/domain/bem/services/propostas"
import PessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import {UFakeLoader} from 'uloc-vue'
import {VMoney} from "v-money";
import {REAL_BRL, convertRealToMoney} from "@/utils/money"
// import SlCheckbox from "@/reuse/input/Checkbox"

const mockProposta = {
  id: null,
  createdAt: null,
  nome: null,
  email: null,
  telefone: null,
  valor: null,
  parcelado: false,
  valorEntrada: false,
  parcelas: null,
  indice: null,
  mensagem: null,
  active: true,
  status: 0,
  bem: null,
  pessoa: {
    id: null,
    name: null,
    type: null
  }
}

export default {
  name: 'BemPropostaWindow',
  directives: {money: VMoney},
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [GlobalPersonMixin],
  components: {
    PessoaInput,
    // SlCheckbox,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    UFakeLoader
    // ErpSelect,
    // UTooltip,
  },
  props: ['id', 'bem', 'options', 'router'],
  data() {
    return {
      money: REAL_BRL,
      loading: false,
      success: false,
      preventBind: true,
      model: this.getMock()
    }
  },
  mounted() {
    if (this.isEditing) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
    isEditing() {
      return this.id || (this.model && this.model.id)
    }
  },
  methods: {
    getMock() {
      let mock = JSON.parse(JSON.stringify(mockProposta))
      return mock
    },
    load() {
      this.loading = true
      find(this.bem.id, this.isEditing)
          .then(response => {
            console.log(response)
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))
      if (data.createdAt) {
        data.createdAt = datetimeToEn(data.createdAt)
      }
      data.valor = convertRealToMoney(data.valor)
      data.valorEntrada = convertRealToMoney(data.valorEntrada)
      data.bem = this.bem
      const relacoes = ['bem', 'pessoa']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      return data
    },
    prepareToEdit(data) {
      const datas = ['createdAt']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date, 'dd/MM/yyyy HH:mm')
        }
      })

      data.valor = 'R$ ' + this.$options.filters.moeda(data.valor || 0)
      data.valorEntrada = 'R$ ' + this.$options.filters.moeda(data.valorEntrada || 0)

      if (data.parcelado === null) {
        data.parcelado = false
      }
      return data
    },
    save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      let method = newProposta
      if (this.model.id) {
        method = updateProposta
      }
      method(this.bem.id, data)
          .then(response => {
            this.loading = false
            console.log(response)
            const isEditing = this.isEditing
            this.model.id = response.data.id
            // this.$uloc.window.close(this.$root.wid)
            if (isEditing) {
              this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
              this.success = true
              if (type === 'andEdit') {
                this.dg()
              } else {
                this.$uloc.notify({
                  color: 'positive',
                  message: `Proposta #${response.data.id} alterada.`,
                  position: 'bottom-right',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
            } else {
              this.$uloc.window.emit(this.$root.wid, 'created', response.data)
              this.$uloc.notify({
                color: 'black',
                message: `Nova Proposta com ID #${response.data.id} criada.`,
                position: 'bottom-right',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]*/
              })
              this.$uloc.window.close(this.$root.wid)
            }
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
